import { React, useState, useEffect } from 'react'
import { useAddItemToCart } from 'gatsby-theme-shopify-manager'

import MainLayout from '../layouts/MainLayout'
import ModalComponent from '../components/ModalComponent/ModalComponent'

const fetchAvailability = async (handle) => {
  const res = await fetch(
    `https://${process.env.GATSBY_SHOPIFY_SHOP_NAME}.myshopify.com/api/2019-07/graphql`,
    {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'x-shopify-storefront-access-token':
          process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN,
        'content-type': 'application/json',
      },
      body: `{"query":"{\\n  productByHandle(handle: \\"${handle}\\") {\\n    id\\n    availableForSale\\n  }\\n}\\n"}`,
    }
  )

  return (await res.json()).data.productByHandle
}

const ProductTemplate = ({ pageContext }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isStocked, setStocked] = useState(null)
  const { product } = pageContext
  const addItemToCart = useAddItemToCart()

  useEffect(() => {
    fetchAvailability(product.handle).then(({ availableForSale }) => {
      if (availableForSale) {
        setStocked(true)
      } else {
        setStocked(false)
      }
    })
  }, [product.handle])

  const addToCart = async (productId) => {
    try {
      const { availableForSale } = await fetchAvailability(product.handle)

      if (availableForSale) {
        await addItemToCart(productId, 1)
        setIsOpen(true)
      } else {
        setStocked(false)
        alert('Sorry, the item is currently out of stock.')
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <MainLayout>
      <div className="container px-8 mx-auto flex items-center flex-wrap pt-4 pb-12 md:grid md:grid-cols-2 md:gap-8">
        {isOpen && <ModalComponent setIsOpen={setIsOpen} />}
        <img
          className="object-fit mx-auto mb-8"
          src={product.images[0].originalSrc}
          alt={product.title}
        ></img>
        <div>
          <h1 className="font-bold mb-4">{product.title}</h1>
          <div className="mb-8">{product.description}</div>
          <div className="mb-8 font-bold">
            Price: ${parseInt(product.priceRange.maxVariantPrice.amount)}
          </div>
          {(() => {
            if (isStocked) {
              return (
                <button
                  onClick={() => addToCart(product.variants[0].shopifyId)}
                  className="snipcart-add-item bg-black hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
                >
                  Add to Cart
                </button>
              )
            } else if (isStocked === null) {
              return <div>Checking stock</div>
            } else {
              return <div>Sorry, out of stock</div>
            }
          })()}
        </div>
      </div>
    </MainLayout>
  )
}
export default ProductTemplate
